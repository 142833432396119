import React from "react"
import {
  Button,
} from 'antd'
import { Link } from "gatsby"
import { TwitterOutlined } from '@ant-design/icons'

class Footer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasConsent: true
    }

    this.setConsent = this.setConsent.bind(this);
  }

  componentDidMount() {
    // console.log('footer did mount');

    if (window && window.CM) {
      if (window.CM.Tools.cookie.get('consent') === 'true') {
        return
      }

      // increment pageviews localStorage or set consent
      if (window.localStorage) {
        let count = window.CM.Tools.localStorage.get('pageviews_count')
        count = count ? parseInt(count, 10)+1 : 1 

        if (count > 3) {
          this.setConsent()
          return
        } else {
          window.CM.Tools.localStorage.set('pageviews_count', count)
        }
      }
    }

    this.setState({hasConsent: false})
  }

  setConsent() {
    if (window && window.CM) {
      window.CM.Tools.cookie.set('consent', 'true', 31000000)
      if (window.cmAgent) {
        window.cmAgent.userConsent()
      }
    }
    this.setState({hasConsent: true})
  }

  render() {

    return <div className="footer">
        <div className="footer-split"></div>

        {this.state.hasConsent === false && <div className="footer-consent">
          We rely on cookies to optimize our communication. By browsing our website, you agree to it. Read more in our <Link to={'/privacy'}>Privacy policy</Link>.
          <Button style={{marginLeft: '16px'}} ghost onClick={this.setConsent}>I agree</Button>
        </div>}

        <div className="container">
          <p className="footer-links">
            <Link to="/terms" style={{display: 'inline-block', margin: '0 30px'}}>Terms of Service</Link>
            <Link to="/privacy" style={{display: 'inline-block', margin: '0 30px'}}>Privacy Policy</Link>
            <a href="https://twitter.com/notifuse" target="_blank" rel="noopener noreferrer" style={{display: 'inline-block', margin: '0 30px'}}><TwitterOutlined style={{paddingRight: '8px'}} /> Twitter</a>
            <a href="mailto:hello@notifuse.com" style={{display: 'inline-block', margin: '0 30px'}}>Contact Us</a>
          </p>

          <img src="/images/logo.png" alt="Notifuse" height="30" />

          <p className="footer-copyright">Distributed by <a href="https://captainmetrics.com" target="_blank">Captain Metrics</a> © {new Date().getFullYear()} </p>
        </div>
      </div>
  }
}

export default Footer