import React, { Component } from "react"
import {
  Col,
  Row,
  Button,
} from 'antd'
import { Link } from "gatsby"
// import Contact from './contact'
import { CloseOutlined, MenuOutlined } from '@ant-design/icons'

class Topbar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      mobileMenuVisible: false,
    }

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  toggleMobileMenu() {
    this.setState({mobileMenuVisible: !this.state.mobileMenuVisible})
  }

  render() {

    return <div id="topbar" className={this.props.spacer ? 'spacer' : ''}>
      <div className="container">
        <Row>
          <Col span={8} id="topbar-left">
            <Link to={'/'}>
              <img src="/images/logo.png" alt="Notifuse" />
            </Link>
          </Col>
          <Col span={16} id="topbar-right">

            <Button className="mobile-button" ghost={this.state.mobileMenuVisible ? false : true} type={this.state.mobileMenuVisible ? 'default' : 'primary'} onClick={this.toggleMobileMenu}>{this.state.mobileMenuVisible ? <CloseOutlined /> : <MenuOutlined />}</Button>
            <ul className={this.state.mobileMenuVisible ? 'visible' : undefined}>
              {/*<li onClick={this.toggleMobileMenu}><Link to={'/how-it-works'}>How it works</Link></li>*/}
              <li onClick={this.toggleMobileMenu}><Link to={'/pricing'}>Pricing</Link></li>
              <li onClick={this.toggleMobileMenu}><a href="https://docs.notifuse.com" target="_blank" rel="noreferrer noopener">Documentation</a></li>
              <li><a href="mailto:hello@notifuse.com">Contact Us</a></li>
              <li><a href="https://console.notifuse.com/"><Button size="small" type="primary" ghost>Sign in</Button></a></li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  }
}

export default Topbar